import React from "react";
import Layout, { FlexSection } from "../components/layout";
import Helmet from "react-helmet";
import { HeaderImage } from "../styledComponents/Blog";
import {
  TemplateBody,
  TemplateContainer
} from "../styledComponents/templates/TemplatePages";
import { H1, Spinner } from "@lewisblackwood/component-library";

const Blog = ({ data }) => {
  const post = data.allMarkdownRemark.edges[0].node;

  return (
    <Layout>
      <Helmet>
        <title>{post.frontmatter.title}</title>
        <meta name="description" content={post.frontmatter.description || ""} />
        <meta property="og:site_name" content="Personably Blog" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={post.frontmatter.title} />
        <meta
          property="og:description"
          content={post.frontmatter.description || ""}
        />
        <meta
          property="og:url"
          content={`https://personably.co/blog${post.fields.slug}`}
        />
        <meta
          property="og:image"
          content={post.og_image || post.feature_image}
        />
        <meta
          property="article:published_time"
          content={post.frontmatter.published_at}
        />
        <meta
          property="article:modified_time"
          content={post.frontmatter.updated_at}
        />
        <meta
          property="article:tag"
          content={post.frontmatter.primary_tag_name}
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={post.frontmatter.title} />
        <meta
          name="twitter:description"
          content={post.frontmatter.description || ""}
        />
        <meta
          name="twitter:url"
          content={`https://personably.co/blog${post.slug}`}
        />
        <meta name="twitter:image" content={post.frontmatter.feature_image} />
        <meta name="twitter:label1" content="Written by" />
        <meta
          name="twitter:data1"
          content={post.frontmatter.primary_author_name}
        />
        <meta name="twitter:label2" content="Filed under" />
        <meta
          name="twitter:data2"
          content={post.frontmatter.primary_tag_name}
        />
        <meta name="twitter:site" content="@personablyco" />
        <meta
          name="twitter:creator"
          content={post.frontmatter.primary_author_twitter}
        />
      </Helmet>
      <FlexSection column="2" as="article">
        <HeaderImage image={post.frontmatter.feature_image} />
        <TemplateContainer>
          <H1 center mb="xlarge">
            {post.frontmatter.title}
          </H1>
          <TemplateBody intro dangerouslySetInnerHTML={{ __html: post.html }} />
        </TemplateContainer>
      </FlexSection>
    </Layout>
  );
};

export const postsQuery = graphql`
  query($slug: String!) {
    allMarkdownRemark(filter: { fields: { slug: { eq: $slug } } }) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            feature_image
            description
            published_at
            updated_at
            primary_tag_name
            primary_author_name
            primary_author_twitter
          }
          html
        }
      }
    }
  }
`;

export default Blog;
