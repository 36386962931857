import styled from "styled-components";
import media from "../Media";

export const TemplateContainer = styled.section`
  max-width: 800px;
  position: relative;
  width: -webkit-fill-available; /* stylelint-disable-line value-no-vendor-prefix */
  width: -moz-available; /* stylelint-disable-line value-no-vendor-prefix */
`;

export const TemplateBody = styled.div`
  p,
  ul,
  ol,
  blockquote {
    color: ${props => props.theme.colours.black.light};
    font-size: ${props => props.theme.bodyFontSize.large};
    margin-bottom: ${props => props.theme.spacing.large};
    line-height: 1.6em;
  }

  p:first-child {
    font-size: ${props => (props.intro ? props.theme.bodyFontSize.xlarge : "")};
    margin-bottom: ${props => (props.intro ? props.theme.spacing.xlarge : "")};
  }

  li {
    margin-bottom: ${props => props.theme.spacing.small};
  }

  h1,
  h2,
  h3,
  h4 {
    font-size: ${props => props.theme.bodyFontSize.xxlarge};
    margin-top: ${props => props.theme.spacing.xlarge};
  }

  img {
    border-radius: 8px;
    width: auto;
    margin: ${props => props.theme.spacing.large} 0;
    max-height: 80vh;
    max-width: 100%;
  }

  hr {
    border: ${props => `1px solid ${props.theme.colours.grey.lightest}`};
    margin: ${props => props.theme.spacing.xlarge};
  }

  a {
    color: ${props => props.theme.colours.purple.dark};
    border-bottom: ${props => `1px solid ${props.theme.colours.purple.dark}`};
  }

  table {
    display: block;
    font-size: 18px;
    line-height: 1.6em;
    margin-bottom: ${props => props.theme.spacing.xlarge};
    overflow: scroll;
  }

  th {
    border-bottom: ${props => `1px solid ${props.theme.colours.grey.light}`};
    padding-bottom: ${props => props.theme.spacing.xxsmall};
    color: ${props => props.theme.colours.black.default};
    text-align: left;
    }

    th:nth-child(2) {
      display: block;
      min-width: 300px
    }

    td {
      padding: ${props =>
        `${props.theme.spacing.large} ${props.theme.spacing.large} 0 0`};
      vertical-align: top;
    }
  }

  strong {
    color: ${props => props.theme.colours.black.default};
  }

  code {
    font-family: monospace !important;
    background-color: #e5eff5;
    padding: 2px;
    border-radius: 3px;
  }

  pre {
    position: relative;
    margin: 0;
    padding: ${props => props.theme.spacing.medium};
    color: ${props => props.theme.colours.white.default};
    background-color: ${props => props.theme.colours.black.default};
    border-radius: 5px;
    overflow-x: auto;

    > code {
      background-color: ${props => props.theme.colours.black.default};
    }
  }

  blockquote {
    border-left: 3px solid ${props => props.theme.colours.pink.default};
    padding-left: ${props => props.theme.spacing.large};
    margin: 0 0 ${props => props.theme.spacing.xlarge} 0;
  }

  figure {
    margin: 0;
    text-align: center;

    ${media.xsmall`
      iframe {
        width: -webkit-fill-available; /* stylelint-disable-line value-no-vendor-prefix */
        width: -moz-available; /* stylelint-disable-line value-no-vendor-prefix */
      }
    `};
  }
`;
